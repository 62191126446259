angular.module('app')
    .component('statements', {
        templateUrl: '/static/templates/accounts/tabs/statements.html',
        controller: Statements,
        controllerAs: 'ctrl'
    });

function Statements($log, $stateParams, accountService) {

    var vm = this;
    vm.driverReference = $stateParams.driverReference;
    vm.accountReference = $stateParams.accountReference;
    vm.accountingOwnerReference = $stateParams.accountingOwnerReference;
    vm.statements = [];
    vm.error = undefined;
    vm.loading = false;
    vm.pagedSet = { page: 0, totalPages: 0 };
    vm.limit = 10;
    vm.page = 0;

    vm.loadMore = loadMore;
    vm.$onInit = init;

    function init() {
        $log.debug("Statements Controller initialized");
        listStatements(0);
    }

    function loadMore() {
        listStatements(vm.pagedSet.page);
    }

    function listStatements(page) {
        if (page === 0) {
            vm.methods = [];
        }

        vm.loadingMore = vm.disableLoadMore = true;

        var params = {
            limit: 10,
            offset: (vm.page * vm.limit),
            sort: vm.sortField,
            endDate: vm.endDate,

        };

        accountService.getStatementList(vm.accountingOwnerReference, vm.accountReference, params)
            .then (function (response) {

                var statements = angular.forEach(response.data, function( statement ){
                    statement.selected = false;
                });
                vm.statements = vm.settlements.concat(statements);
                vm.pagedSet = response.header;
                vm.disableLoadMore = (vm.pagedSet.page >= vm.pagedSet.totalPages);

            }, function(error) {

                vm.error = error;
                vm.disableLoadMore = true;

            })['finally'](function () {

            vm.loading = false;
            vm.loadingMore = false;


        });
    }


}